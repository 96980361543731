import React from 'react'
import { motion } from 'framer-motion'

const Content = (props) => {

	const images = props.data.images.map((image) => {
		return(
			<img key={image} src={'/images/' + image}/>
		)
	})

	const videos = props.data.videos.map((video) => {
		return(
			 <iframe key={video} src={'https://www.youtube.com/embed/' + video} allow="fullscreen"></iframe> 
		)
	})

	const models = props.data.models.map((model) => {
		return(
			 <iframe key={model} src={'https://sketchfab.com/models/' + model + '/embed?autostart=0&autospin=0.5&annotations_visible=0'}></iframe> 
		)
	})

	const links = props.data.links.map((link) => {
		return(
			 <a key={link} href={link[1]}>{link[0]}</a>
		)
	})

	return (
		<motion.div className='content' initial={props.transition.out} animate={props.transition.in}>
			<div className='content-main'>
				<h1>{props.data.title}</h1>
				<p>{props.data.text}</p>
			</div>

			{images.length > 0 &&
			    <div className='content-imageContainer'>
			    	<h2>images</h2>
			    	<div className='content-imageContainer-table'>
			    		{images}
			    	</div>
			    </div>      
			}

			{videos.length > 0 &&
			    <div className='content-videoContainer'>
			    	<h2>videos</h2>
			    	{videos}
			    </div>      
			}

			{models.length > 0 &&
			    <div className='content-modelContainer'>
			    	{models}
			    </div>
			}

			{links.length > 0 &&
			    <div className='content-linkContainer'>
			    	<h2>links:</h2>
			    	{links}
			    </div>      
			}

		</motion.div>
	)
}

export default Content