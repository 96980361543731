import React from 'react'
import { Link } from 'react-router-dom'

const Menu = () => {
	return (
		<div className='menu'>
			<ul>
				<li><Link to="/elnur">elnur</Link></li>
			</ul>
			<ul>
				<li><Link to="/sabina">sabina</Link></li>
			</ul>
			<ul>
				<li><Link to="/nadir">nadir</Link></li>
			</ul>
			<ul>
				<li><Link to="/chinara">chinara</Link></li>
			</ul>
			<ul>
				<li><Link to="/alibala">alibala</Link></li>
			</ul>
			<ul>
				<li><Link to="/elxan">elxan</Link></li>
			</ul>
			<ul>
				<li><Link to="/fazil">fazil</Link></li>
			</ul>
		</div>
	)
}

export default Menu