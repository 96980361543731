import React from 'react'
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect"

const Info = (props) => {

	const professions = props.data.professions.map(profession => {
		return(
			<li key={profession}>{profession}</li>
		)
	})

	return (
		<div className='info'>
			<div className='info-head'>
				<img src={"/images/" + props.data.image}/>
				<div>
					<h1>{props.data.name}</h1>
					<BrowserView>
						<ul>
							{professions}
						</ul>
					</BrowserView>
				</div>
				<MobileView className='professions-mobile'>
					<ul>
						{professions}
					</ul>
				</MobileView>
			</div>
			<div className='info-text'>
				<p>{props.data.text}</p>
			</div>
		</div>
	)
}

export default Info