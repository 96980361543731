import React from 'react'
import { motion } from 'framer-motion'
import emailjs from 'emailjs-com'

const Contact = (props) => {

	function sendEmail(e) {
	    e.preventDefault()

	    emailjs.sendForm('service_ex2bkzu', 'template_1036imc', e.target, 'user_m0HE75rEoNY7WnlkXwt62')
	      .then((result) => {
	          console.log(result.text)
	      }, (error) => {
	          console.log(error.text)
	      })
	      e.target.reset()
  	}

	return (
		<motion.div className='contact' initial={props.transition.out} animate={props.transition.in}>
			<h1>contact {props.data.name}</h1>
			<form className='contactForm' onSubmit={sendEmail}>			
				<input className='contactForm-info' type="text" name="name" placeholder="Name"/>
				<input className='contactForm-info' type="text" name="surname" placeholder="Surname"/>
				<input className='contactForm-info' type="email" name="email" placeholder="Email"/>
				<input className='contactForm-subject' type="text" name="subject" placeholder="Subject"/>
			  	<textarea placeholder="Message" name="message"></textarea>
			  	<input type="hidden" name="to-email" value={props.data.email}/>
			  	<input className='contactForm-button' type="submit" value="Send"/>
			</form>
		</motion.div>
	)
}

export default Contact