import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, useLocation} from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import Axios from 'axios'

import Header from './components/Header'
import Home from './components/Home'
import Profile from './components/Profile.js'
import Content from './components/Content.js'
import Contact from './components/Contact.js'

function App() {

	const [data, setData] = useState([
		{
			info: {
				name: "",
				image: "",
				email: "",
				text: "",
				professions: [],
				parent: true
			},
			contents: []
		}
	])
	const [partners, setPartners] = useState([])
	const [headerVisibility, setHeaderVisibility] = useState(true)

	const getData = () => {
		Axios.get("/data.json").then(response => {
			setData(response.data)
		})
	}

	const getPartners = () => {
		Axios.get("/partners.json").then(response => {
			setPartners(response.data)
		})
	}

	useEffect(()=>{
		getData()
		getPartners()

		if (window.location.href.indexOf("elnurdesigner") > -1) {
			console.log("your url contains the name elnurdesigner")
			setHeaderVisibility(false)
		}
		else {
			setHeaderVisibility(true)
		}
    }, [])

	const location = useLocation()
	
	const pageTransition = {
		in: {
			y: 0,
			height: 'auto'
		},
		out: {
			y: -50,
			height: 0
		}
	}

	const profiles = data.map(profile => {

		return(
			<Route key={profile.info.name} path={"/" + profile.info.name}>
				<Profile data={profile} transition={pageTransition}/>
			</Route>
		)
	})

	const elnurProfile = (
		<Route key={"elnurdesigner"} path={"/elnurdesigner"}>
			<Profile data={data[0]} transition={pageTransition}/>
		</Route>
	)
			
	const contents = data.map(profile => {

		return profile.contents.map(content => {
			return(
				<Route key={profile.info.name + content.title} path={"/" + profile.info.name + "/" + content.title}>
					<Content data={content} transition={pageTransition}/>
				</Route>
			)
		})
	})

	const elnurContents = data[0].contents.map(content => {
			return(
				<Route key={"elnurdesigner" + content.title} path={"/elnurdesigner/" + content.title}>
					<Content data={content} transition={pageTransition}/>
				</Route>
			)
	})

	const contacts = data.map(profile => {

		return(
			<Route key={profile.info.name + "contact"} path={"/" + profile.info.name + "/contact"}>
				<Contact data={profile.info} transition={pageTransition}/>
			</Route>
		)
	})

	const elnurContact = (
		<Route key={"elnurdesignercontact"} path={"/elnurdesigner/contact"}>
				<Contact data={data[0].info} transition={pageTransition}/>
		</Route>
	)

	return(
		<div className='main'>
			{headerVisibility && <Header/>}

			<AnimatePresence exitBeforeEnter>
				<Switch location={location} key={location.pathname}>
					<Route exact path="/">
						<Home data={data} partners={partners} transition={pageTransition}/>
					</Route>
					{profiles}
					{elnurProfile}
				</Switch>
			</AnimatePresence>

			<AnimatePresence exitBeforeEnter>
				<Switch location={location} key={location.pathname}>
					{contents}
					{elnurContents}
					{contacts}
					{elnurContact}
				</Switch>
			</AnimatePresence>

		</div>
	)
}

export default App