import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

const Home = (props) => {

	const parents = props.data.map(parent => {
		if(parent.info.parent) {
			return(
				<ul key={parent.info.name}>
					<Link to={"/" + parent.info.name}>
						<h2>{parent.info.name}</h2>
						<img src={"images/" + parent.info.image}/>
					</Link>
				</ul>
			)
		}
		else return null
	})

	const children = props.data.map(child => {
		if(!child.info.parent) {
			return(
				<ul key={child.info.name}>
					<Link to={"/" + child.info.name}>
						<span></span>
						<img src={"images/" + child.info.image}/>
						<h2>{child.info.name}</h2>
					</Link>
				</ul>
			)
		}
		else return null
	})

	const partners = props.partners.map(partner => {
		return(
			<div key={partner.name}>
				<a href={partner.link}>
					<img src={"images/" + partner.image}/>
					<h2>{partner.name}</h2>
				</a>
			</div>
		)
	})

	return (
		<motion.div className='home' initial={props.transition.out} animate={props.transition.in}>
			<h1>Alishly family</h1>
			<div className='parents'>
				{parents}
				<span className='horizontal-short'></span>
				<span className='vertical'></span>
				<span className='horizontal-long'></span>
			</div>
			<div className='children'>
				{children}
			</div>

			<div className='partners'>
				<h1>partners</h1>
				{partners}
			</div>
			
		</motion.div>
	)
}

export default Home