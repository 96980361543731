import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'


const Submenu = (props) => {

	let { url } = useRouteMatch();

	const contents = props.data.map(content => {
		return(
			<ul key={content.title}>
				<li><Link to={`${url}/`+content.title}>{content.title}</Link></li>
			</ul>
		)
	})

	return (
		<div className='submenu'>
			{contents}
			<ul>
				<li><Link to={`${url}/`+ 'contact'}>contact</Link></li>
			</ul>
		</div>
	)
}

export default Submenu