import React from 'react'
import { motion } from 'framer-motion'

import Info from './Info'
import Submenu from './Submenu'

const Profile = (props) => {

	return (
		<motion.div className='profile' initial={props.transition.out} animate={props.transition.in}>
			<Info data={props.data.info}/>
			<Submenu data={props.data.contents}/>		
		</motion.div>
	)
}

export default Profile